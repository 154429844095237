<!--商品订单-->
<template>
  <div class="good">
    <!-- 查詢 -->
    <el-row :gutter="10" style="margin-bottom: 20px;">
      <el-col :span="4">  <el-input
          v-model="tableParams.kw"
          placeholder="输入手机号搜索"
          @input="kwChange"
          clearable
          class="mr15"
        /></el-col>
      <el-col :span="4">
        <el-select
          v-model="tableParams.businessType"
          placeholder="请选择订单类型"
          @change="reload"
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(shopObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <SelecteMS @reload="update" ></SelecteMS>
      </el-col>
      
    </el-row>
   
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="下单时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}丨{{ scope.row.mobile }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属商户" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.merchantName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属门店" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.shopName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsInfo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单位置" align="center">
        <template slot-scope="scope">
          <span v-if="tableParams.businessType == 3"
            >{{ scope.row.serviceName || "-" }} &nbsp;-&nbsp;
            {{ scope.row.roomName || "-" }}</span
          >
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="支付方式" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentInfo }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </section>
  </div>
</template>
<script>
import { ObjToOpt } from "@/utils/utils";
import { shopObj } from "@/db/objs";
import { getOrderList } from "@/api/mall/mall";
import { getIdroomList} from "@/api/order/order";
import SelecteMS from '.././components/selecteMS'

export default {
  components:{
    SelecteMS
  },
  data() {
    return {
      ObjToOpt,
      shopObj,
      tableParams: {
        kw: "",
        page: 1,
        size: 10,
        businessType: 2,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        memberId: "",
      },
      tableData: [],
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.tableParams.memberId = this.$route.query.memberId;
  },
  mounted() {
    this.getOrderList();

  },
  methods: {
    // 【请求】表格数据
    getOrderList() {
      let data = this.tableParams;
      getOrderList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    kwChange(e) {
      this.tableParams.kw = e;
      this.reload();
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getOrderList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getOrderList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
    
    update(e){
      this.tableParams.merchantId = e.merchantId;
      this.tableParams.shopId = e.shopId;
      this.reload()
    },
  },
};
</script>
<style lang="scss" scoped>
.good {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>